import React from 'react'
import FrameImage from './FrameImage'
import { CssBaseline } from '@mui/material'

export default function OpenFram() {
    return (
        <div>
            <FrameImage />
            <CssBaseline />
        </div>
    )
}
