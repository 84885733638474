import React, { useState } from "react";
import { Stack, Box, Button, TextField, Grid, Tooltip } from "@mui/material";
import html2canvas from "html2canvas";
//Srcs
import "./pagestyle.scss";
import CropImageFile from "../Components/CropImage/CropImageFile";
import FramImage from "../Assets/Facebook-Fram.png";
import EmptyImage from "../Assets/empty-image.png";

export default function FrameImage() {
  // ========================= upload Image =========================
  const [loading, setLoading] = useState(false);
  const [checkDownLoad, setCheckDownLoad] = useState(false);
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };


  // ======================== handleDownload image =========================

  const handleCheckDownload = () => {
    setCheckDownLoad(true);
    setLoading(true)
    setTimeout(() => {
      handleDownloadImage();
    }, 1500);
  };

  const printRef = React.useRef();

  const handleDownloadImage = async () => {
  
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/png");
    const link = document.createElement("a");


    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.png";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }

    setTimeout(() => {
      setCheckDownLoad(false);
      setLoading(false)
      setOpenCrop(false)
    }, 2000);
    
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      className="page-container"
    >
      <Stack direction="row" justifyContent="center" width="100%">
        <Box>              
          <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12}>
                            
              { checkDownLoad ? (
                <Stack direction="row" justifyContent="center">
                  <Box className="image-container-download-preview" ref={printRef}>
                    <img
                      src={URL.createObjectURL(imageFile)}
                      className="avatar-image-download-preview"
                    />
                    <img src={FramImage} className="frame-image-download-preview" />
                  </Box>
                </Stack>
              ) : (
                <Stack direction="row" justifyContent="center">                  
                  {!openCrop ? (
                    <Tooltip title="click to upload">
                      <Button component="label" className="btn-container">
                        <TextField
                          type="file"
                          id="image"
                          sx={{ display: "none" }}
                          onChange={handleUploadImage}
                        />                        
                        { imageFile ? (
                          <Box className="image-container">                            
                            <img
                              src={URL.createObjectURL(imageFile)}
                              className="avatar-image"
                            />
                            <img src={FramImage} className="frame-image" />
                          </Box>
                        ) : (
                          <Box className="image-container">                            
                            <img className="avatar-image" src={EmptyImage} />
                            <img src={FramImage} className="frame-image" />
                          </Box>
                        )}
                      </Button>
                    </Tooltip>
                  ) : (
                    <CropImageFile
                      openCrop={openCrop}
                      setOpenCrop={setOpenCrop}
                      photoURL={photoURL}
                      setPhotoURL={setPhotoURL}
                      setImageFile={setImageFile}
                      setProfileHook={setProfileHook}
                    />
                  )}
                </Stack>
              )}

            </Grid>

            {imageFile && !openCrop ? (
              <Grid item xs={12} display="flex" justifyContent="center">
                {loading ? (
                  <Stack direction="row" justifyContent="center" sx={{paddingRight: "20px" , paddingLeft: "20px"}}>
                    <Button className="btn-download" fullWidth>
                      Loading...
                    </Button>
                  </Stack>
                ) : (
                  <Stack direction="row" justifyContent="center" sx={{paddingRight: "20px" , paddingLeft: "20px"}}>
                    <Button
                      className="btn-download"
                      fullWidth
                      onClick={handleCheckDownload}
                    >
                      Download
                    </Button>
                  </Stack>
                )}
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Box>
      </Stack>
    </Stack>
  );
}
