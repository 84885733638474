import React from "react";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from "@mui/material/styles";
//Srcs
import "./App.css";
import FrameImage from "./Pages/FrameImage";
import FrameImageTwo from "./Pages/FrameImageTwo";
import { Button } from "@mui/material";
import OpenFram from "./Pages/OpenFram";
import Home from "./Pages/Home";

function App() {
  //================ CREATE THEME ====================
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          background: {
            default: "#EBEDEF",
          },
        },

        typography: {
          fontFamily: ["Century Gothic", "Siemreap"].join(","),
        },
      }),

    []
  );

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route exact path='/frame' element={<OpenFram />}></Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
