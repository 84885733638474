import { Button, Stack } from '@mui/material'
import React from 'react'

export default function Home() {
    const openNewLink = () => {
        window.open("https://frames.go-globalschool.com/frame","_system");
    }
    return (
        <Stack direction="row" justifyContent="center" marginTop="150px" >
            <Button variant="contained" onClick={() => openNewLink()}>បង្កើតស៊ុមរូបភាព</Button>
        </Stack>
    )
}
